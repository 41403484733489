import React from "react";
import { Link } from "gatsby";
import "../../assets/css/demo-style-3.css";

const ShortTitles = props => {
    return (
        <div class="demo-cards-3">
            <div>
                <img src="https://erp.jciindia.in/lib/assets/images/foundation/frame-3.png" class="image-frame" />
                <div>
                    <Link to={props.blogContent.TitleLink}> <img class="image" src={props.blogContent.ImagePath} alt="avatar" /> </Link>
                </div>
                <div class="title_bar">
                    <Link to={props.blogContent.TitleLink} class="title">
                        {props.blogContent.Title}
                    </Link>
                </div>
            </div>
            {/* <div>
                <img src="http://localhost/test/team/frame/frame.png" class="image-frame" />
                <div>
                    <Link to={props.blogContent.TitleLink}> <img class="image" src={props.blogContent.ImagePath} alt="avatar" /> </Link>
                </div>
                <div class="title_bar">
                    <Link to={props.blogContent.TitleLink}>
                        <h1 class="title">{props.blogContent.Title}</h1>
                    </Link>
                </div>
            </div> */}
        </div>
    );
};

export default ShortTitles;
